import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  type PropsWithChildren,
} from 'react'

import { useFeatureFlag } from 'configcat-react'

import { type DataTableOrder } from '@shared/components'
import { useEvents } from '@shared/events'
import { useStorage } from '@shared/hooks'

import { pt } from '@shared/locales'
import { fieldsGroups } from './fields-groups'
import {
  type FilterFieldGroup,
  type Filters,
  type FilterValues,
  type Ordination,
  type Pagination,
} from './filters-provider.types'

const FiltersProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const filterStg = useStorage<FilterValues>('segmentation:filters')
  const pageStg = useStorage<number>('segmentation:pagination')
  const ordinationStg = useStorage<Ordination>('segmentation:ordination')
  const { value: enableActivationRegister } = useFeatureFlag('enableActivationRegister', false)
  const { value: showMission } = useFeatureFlag('mission', false)
  const events = useEvents()

  const [current, setCurrent] = useState<Filters>({ sectorId: [], ...filterStg.get() })
  const [pagination, setPagination] = useState<Pagination>({
    page: pageStg.get() ?? 1,
    totalItems: 0,
    pageSize: 200,
    totalPages: 0,
  })

  const [ordination, setOrdination] = useState<Ordination>({
    sortField: '',
    sortOrder: '' as DataTableOrder,
    ...ordinationStg.get(),
  })

  const changeFilters = useCallback(
    (filters: FilterValues) => {
      pageStg.set(1)
      setPagination((p) => ({ ...p, page: 1 }))
      setCurrent((c) => {
        const updatedFilters = { ...c, ...filters }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { sectorId: NEVER_SAVE_TO_STORAGE, ...filtersToStore } = updatedFilters
        filterStg.set(filtersToStore)
        events.clickApplyFilters(updatedFilters)

        return updatedFilters
      })
    },
    [filterStg, pageStg, events],
  )

  const cleanFilters = useCallback(() => {
    setPagination((p) => ({ ...p, page: 1 }))
    setCurrent((f) => ({ sectorId: f.sectorId }))
    filterStg.clean()
  }, [filterStg])

  const changeOrdination = useCallback(
    (ord: Ordination) => {
      pageStg.set(1)
      ordinationStg.set(ord)
      setPagination((p) => ({ ...p, page: 1 }))
      setOrdination(ord)
    },
    [pageStg, ordinationStg],
  )

  const changePagination = useCallback(
    (pag: Partial<Pagination>) => {
      setPagination((p) => {
        const next = { ...p, ...pag }
        pageStg.set(next.page)
        return next
      })
    },
    [pageStg],
  )

  const filterFieldGroups = (): FilterFieldGroup[] => {
    const triggers = enableActivationRegister
      ? fieldsGroups
      : fieldsGroups.filter((g) => g.description !== pt.filtersProvider.group.triggers)

    const mission = showMission
      ? fieldsGroups
      : fieldsGroups.filter((g) => g.description !== pt.filtersProvider.group.mission)

    return triggers && mission
  }

  return (
    <FilterContext.Provider
      value={{
        current,
        pagination,
        ordination,
        fieldsGroups: filterFieldGroups(),
        changeOrdination,
        changePagination,
        changeFilters,
        cleanFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

const useFiltersContext = (): FilterContextProps => {
  const ctx = useContext(FilterContext)
  if (!ctx) throw new Error('Filters provider not found')
  return ctx
}

const FilterContext = createContext<FilterContextProps | null>(null)

interface FilterContextProps {
  fieldsGroups: FilterFieldGroup[]
  current: Filters
  ordination: Ordination
  pagination: Pagination
  changeFilters: (filter: Partial<Filters>) => void
  cleanFilters: () => void
  changeOrdination: (ordination: Ordination) => void
  changePagination: (page: Partial<Pagination>) => void
}

export { FilterContext, FiltersProvider, useFiltersContext, type FilterContextProps }
