import { HttpClient, HttpMethod } from '@grupoboticario/vdi-mfe-utils'

import { authService } from '@vdi/auth-service'

import { env } from '@shared/env'

import { type API } from './api.types'

const createApi = (): API => {
  const http = HttpClient.create({
    baseUrl: env.API_BASE_URL,
    timeout: parseInt(env.API_TIMEOUT) || 60000,
    getHeaders,
  })

  return {
    getResellerList: (params) => http.query({ endpoint: '/resellers/segmentation', params }),
    getResellerCredit: ({ resellerId }) =>
      http.query({ endpoint: `/resellers/${resellerId}/credits` }),
    getReseller: ({ resellerId }) => http.query({ endpoint: `/resellers/${resellerId}` }),
    getSectors: () => http.query({ endpoint: '/sectors', params: { withName: true } }),
    getResellerAdditionalInfo: ({ resellerId }) =>
      http.query({ endpoint: `/v2/resellers/${resellerId}/additional-information` }),
    getTopPurchasedProducts: ({ resellerId }) =>
      http.query({ endpoint: `/resellers/${resellerId}/top-purchased-products` }),
    getCities: (params) => http.query({ endpoint: '/resellers/segmentation/cities', params }),
    getZonesER: (params) => http.query({ endpoint: '/resellers/segmentation/zoneer', params }),
    getCS: (params) => http.query({ endpoint: '/resellers/segmentation/cs', params }),
    updateResellerData: ({ resellerId }) =>
      http.mutate({
        method: HttpMethod.Post,
        endpoint: '/resellers',
        body: { geraId: resellerId },
      }),
    getFidelityRange: (params) =>
      http.query({ endpoint: '/resellers/segmentation/fidelity', params }),
    getPromotions: (params) =>
      http.query({ endpoint: '/resellers/segmentation/promotions', params }),
    getBrandsBought: (params) =>
      http.query({ endpoint: '/resellers/segmentation/brandsbought', params }),
    getAppAdhesion: (params) =>
      http.query({ endpoint: '/resellers/segmentation/appadhesion', params }),
    getHarvestOptions: (params) =>
      http.query({ endpoint: '/resellers/segmentation/harvest', params }),
    getCurrentCycles: (params) =>
      http.query({ endpoint: '/resellers/segmentation/current-cycles', params }),
    registerActivations: (body) =>
      http.mutate({ method: HttpMethod.Post, endpoint: '/activation-record', body }),
    getMLDLink: ({ resellerId }) => http.query({ endpoint: `/resellers/mld/${resellerId}` }),
    getReasonsActivationRecord: (params) =>
      http.query({ endpoint: '/attendances/reasons', params }),
    getMissions: () => http.query({ endpoint: '/segmentation/missions' }),
  }
}

const getHeaders = async (): Promise<Record<string, string>> => {
  const { token } = await authService.getTokenOrRedirect()
  return {
    authorization: `Bearer ${token}`,
    'content-type': 'application/json',
  }
}

export { createApi }
