import { styled, Text } from '@grupoboticario/flora-react'

const Container = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  flex: 1,
  borderRadius: '$medium',
  backgroundColor: '$nonInteractiveAltPredominant !important',
  outlineWidth: '2px',
  outlineStyle: 'solid',
  outlineColor: 'transparent',
  paddingTop: '$4',
  paddingBottom: '$4',
  paddingRight: '$8',
  transitionProperty: 'outline-color',
  transitionDuration: '0.2s',
  transitionTimingFunction: 'ease-out',
  width: '100%',
  paddingLeft: '$8',
  minHeight: '$18',
})

const StyledTitle = styled(Text, {
  color: '$nonInteractiveAuxiliar',
  fontSize: '0.6875rem',
  fontWeight: '$medium',
  letterSpacing: '0.08rem',
  lineHeight: '$short',
  textTransform: 'uppercase',
})

const IconContainer = styled('div', {
  paddingRight: '$3',
  paddingTop: '$2',
  '& span': {
    width: '24px',
  },
})

const TextContainer = styled('div', {
  '& span': {
    display: 'block',
  },
})

export { Container, StyledTitle, IconContainer, TextContainer }
