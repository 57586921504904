import { Text } from '@grupoboticario/flora-react'
import { Container, IconContainer, StyledTitle, TextContainer } from './mission.styles'
import { MissionContainerProps } from './mission.types'
import { MyLocationIcon } from '@grupoboticario/flora-react-icons'
import { pt } from '@shared/locales'

const Mission: React.FC<MissionContainerProps> = ({ mission }: MissionContainerProps) => {
  const header = pt.resellerList.detailReMission
  return (
    <Container>
      <IconContainer>
        <MyLocationIcon color="$nonInteractiveEmphasis" size="$hug" />
      </IconContainer>
      <TextContainer>
        <StyledTitle>{header}</StyledTitle>
        <Text size="bodyLargeStandardMedium" weight="medium" color="$nonInteractiveEmphasis">
          {mission}
        </Text>
      </TextContainer>
    </Container>
  )
}

export { Mission }
